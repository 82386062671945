html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif;
    background-color: #fbfbfb;
}

.main-wrapper {
    overflow-x: hidden;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1149px;
    position: relative;
}
@media only screen and (max-width: 1149px) {
    .container {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}
h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 72px;
}

h1 .thin {
    font-weight: 300;
}

h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 62px;
    margin-bottom: 25px;
}

p {
    font-size: 16px;
    line-height: 30px;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

/* Banner */
.banner {
    background: #ffffff;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center;
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box {
    background: #eaf2f8;
    border-radius: 10px;
}
.banner .logo-box a {
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    color: #090909;
}
.banner .info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7d7f82;
    position: relative;
    margin-left: 15px;
    padding: 0;
}
.banner .info:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #f27558;
}
.banner .domaner-btn {
    background: #f27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    text-decoration: none;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .banner .info {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
@media only screen and (max-width: 940px) {
    .banner {
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a {
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title {
        line-height: 24px;
        text-align: center;
    }
    .banner .info {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before {
        content: none;
    }
}
@media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
}

.site-logo {
    font-size: 0px;
    display: inline-flex;
    align-items: center;
    background: url(../image/logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 240px;
    height: 28px;
}
.navlogo {
    color: #fff;
}
.site-logo svg {
    margin-right: 15px;
}

.logo-wrapper {
    padding-top: 25px;
    padding-bottom: 22px;
}

.site-header {
    color: #fff;
    background: linear-gradient(152.12deg, #232323 11.08%, #000000 66.16%);
}

.site-header .container {
    position: relative;
}

.header-image {
    position: absolute;
    width: 965px;
    height: 616px;
    top: 200px;
    right: -372px;
    z-index: 1;
    background-image: url('../image/header-image.webp');
    background-repeat: no-repeat;
}

.header-wrapper {
    position: relative;
}
.header-wrapper:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 40%;
    left: 0;
    top: 70%;
    background-color: #fbfbfb;
    transform-origin: top left;
    transform: skewY(6deg);
    z-index: 10;
}

.site-header-inner {
    border-radius: 29px;
    position: relative;
    padding: 45px 0 75px;
    z-index: 2;
}

.header-description {
    margin-bottom: 60px;
}

.nav-wrapper p {
    margin-bottom: 54px;
    letter-spacing: 0.345em;
}

.nav-wrapper ul {
    display: flex;
}

.nav-wrapper li {
    box-shadow: 0px 4px 33px 6px rgba(0, 0, 0, 0.1);
    width: 256px;
    height: 65px;
    border-radius: 50px;
    margin-right: 31px;
    padding-left: 0;
}

.gradient-btn {
    background: #141414;
    box-shadow: 5px 13px 39px -8px rgba(245, 245, 255, 0.2);
    border-radius: 5px;
    width: 256px;
    height: 65px;
}
.inner {
    background: #141414;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    position: relative;
    transition: font-size 0.4s linear;
}
.gradient-btn .inner:after {
    background: linear-gradient(82.59deg, #17b4ff 14.22%, #6f6dff 95.16%);
    border-radius: inherit;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.4s linear;
    z-index: 1;
}
.gradient-btn:hover .inner:after {
    opacity: 1;
}
.btn {
    align-items: center;
    background-color: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    font-size: 18px;
    height: 100%;
    justify-content: flex-start;
    padding-left: 25px;
    position: relative;
    text-align: left;
    text-decoration: none;
    width: 100%;
    z-index: 2;
}
.inner a svg {
    margin-right: 14px;
}

.contact-btn {
    background: #141414;
    box-shadow: 5px 13px 39px -8px rgba(54, 54, 54, 0.2);
    border-radius: 5px;
    width: 256px;
    height: 65px;
    font-size: 18px;
    display: inline-block;

    align-items: center;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    justify-content: flex-start;
    padding-left: 25px;
    position: relative;
    text-align: left;
    text-decoration: none;
}
.contact-btn svg {
    margin-right: 10px;
}

/* .nav-wrapper li:nth-of-type(1) a:before {
    background-image: url('../image/gift-box.png');
}

.nav-wrapper li:hover:nth-of-type(1) a:before {
    background-image: url('../image/gift-box-white.png');
}

.nav-wrapper li:nth-of-type(2) a:before {
    background-image: url('../image/about.png');
}

.nav-wrapper li:hover:nth-of-type(2) a:before {
    background-image: url('../image/about-white.png');
} */

footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

footer .copyright {
    color: #828c98;
    font-size: 14px;
}
.footer-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}
footer .site-logo {
    background-image: url(../image/logo_black.png);
}
.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

.header-description {
    font-size: 18px;
    width: 722px;
    height: 64px;
    line-height: 32px;
    color: #d4d4d4;
}

.securemynfts {
    text-align: center;
    margin-bottom: 50px;
    background-color: white;
    color: black;
    border-radius: 5px 5px 0 0;
    padding: 85px 119px 70px;
    margin-bottom: 0;
    background-color: #fbfbfb;

    position: relative;
    z-index: 1000;
}

.cta-buttons {
    margin-bottom: 45px;
}

.main-text {
    font-size: 16px;
    line-height: 30px;
    color: #4e4e4e;
}

/* Choosing wallet section */
.wallet-img {
    background: url('../image/wallet.png');
    width: 295px;
    height: 244px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    margin-top: 4em;
    margin-left: 3.2em;
}
.security.col2 {
    display: flex;
    padding: 25px 65px 25px;
    background-color: #fbfbfb;
    position: relative;
    z-index: 100;
}
section {
    display: inline-block;
}
.visualization {
    width: 50%;
}
.content {
    width: 50%;
}
.content p {
    font-size: 16px;
    line-height: 30px;
    color: #4e4e4e;
}
.signature-img {
    background-image: url('../image/signature.png');
    width: 339px;
    height: 335px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    margin-top: 4em;
    margin-left: 7em;
}
.complexity-img {
    background-image: url('../image/complexity.png');
    width: 305px;
    height: 280px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    margin-left: 3em;
}
.heading3 {
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 25px;
}
.heading33 {
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 25px;
    color: #fff;
    line-height: 42px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}
.main {
    position: relative;
    overflow: hidden;
}
/* .main:after{
    position: absolute;
    content: "";
    width: 100%;
    background: rgb(0, 0, 0);
    max-width: 100%;
    height: 500px;
    top: -22em;
    left: 0;
    transform: scale(1.1) rotate(6deg);
} */
.info-wrapper {
    background: linear-gradient(144.7deg, #232323 13.27%, #000000 79.27%);
    position: relative;
    z-index: 1;
}
.info-div-bg {
    background-image: url('../image/stock.webp');
    background-position: 0%;
    background-repeat: no-repeat;
    opacity: 80%;
    background-size: cover;

    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.info-div-inner {
    text-align: center;
}

.info-div h2 {
    font-size: 42px;
    font-weight: 900;
    color: #fff;
    line-height: 62px;
    padding-top: 92px;
}
.info-text {
    color: #ececec;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    padding-bottom: 40px;
}
.first {
    max-width: 951px;
    margin: 0 auto;
}
.last {
    padding-bottom: 103px;
}
.down-arrow {
    padding-bottom: 41px;
    position: relative;
    animation: MoveUpDown 1s linear infinite;
}
@keyframes MoveUpDown {
    0%,
    100% {
        bottom: 0;
    }
    50% {
        bottom: 10px;
    }
}
.last-paragraph {
    padding-bottom: 120px;
}
.our-team {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 90px;
    padding-top: 60px;
}
.our-team-text {
    color: #4e4e4e;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    padding-bottom: 25px;
}
.info-link {
    color: #389aff;
    font-weight: 700;
}

.logo-wrapper.footer {
    padding-bottom: 8px;
}

/* RESPONSIVE */

@media screen and (min-width: 1300px) {
    .site-header::after {
        background-size: 950px;
        bottom: 10%;
    }
}
@media screen and (max-width: 1025px) {
    .site-header::after {
        display: none;
    }
    .container {
        padding: 0 20px;
    }
}

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 24px;
    }

    h2 {
        font-size: 28px;
        line-height: 36px;
    }

    .mobile-detector {
        display: none;
    }

    .m-visible {
        display: initial;
    }

    .m-hidden {
        display: none;
    }
    .site-header-inner {
        padding: 26px 20px 28px;
    }
    .header-description {
        margin-bottom: 26px;
        max-width: 100%;
        height: auto;
    }

    .nav-wrapper p {
        margin-bottom: 20px;
    }

    .nav-wrapper ul {
        display: block;
    }

    .nav-wrapper ul li:not(:last-child) {
        margin-bottom: 16px;
    }

    .nav-wrapper li {
        width: 100%;
    }

    footer {
        padding-left: 0;
    }
    .footer-wrapper {
        flex-direction: column;
    }

    footer .container {
        flex-direction: column;
        padding-bottom: 24px;
    }

    footer .logo-wrapper {
        padding-bottom: 8px;
    }

    .securemynfts {
        padding: 2em 1.5em 2em;
    }
    .security.col2 {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .visualization {
        width: 90%;
    }
    .wallet-img {
        width: 15em;
        height: 12.4em;
        margin-left: auto;
        margin-right: auto;
        background-size: contain;
        margin-top: 3em;
    }
    .content {
        width: 120%;
    }
    .content p {
        font-size: 15px;
    }
    .main-text {
        font-size: 15px;
    }
    .heading3 {
        margin-top: 1.5em;
        font-size: 25px;
    }
    .security.col2 {
        padding: 25px 0em 25px;
    }

    .security.col2.inverse-column {
        flex-direction: column-reverse;
    }
    .signature-img {
        background-size: contain;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1em;
        height: 10em;
    }
    .complexity-img {
        margin: 0;
        background-size: contain;
        height: 10em;
        margin-left: auto;
        margin-right: auto;
    }
    .last-paragraph {
        padding-bottom: 90px;
    }
    .our-team {
        grid-template-columns: 1fr;
    }
    .our-team-text {
        font-size: 15px;
    }
    .info-text {
        font-size: 15px;
    }
    .info-div h2 {
        font-size: 30px;
        line-height: 40px;
    }
    .heading33 {
        font-size: 22px;
    }
} /* } Responsive max-width 768px */

@media only screen and (max-width: 990px) {
    .header-image {
        width: 720px;
    }
    .gradient-btn {
        width: 225px;
    }
    .security.col2 {
        flex-direction: column;
    }
    .wallet-img {
        margin: 0 auto 1.5em;
    }
    .visualization {
        width: 100%;
    }
    .content {
        text-align: center;
        width: 100%;
    }
    .security.col2.inverse-column {
        flex-direction: column-reverse;
    }
    .signature-img {
        margin: 1em auto;
        background-size: contain;
        width: 290px;
    }
    .complexity-img {
        margin: 0 auto 2em;
        background-size: contain;
        width: 279px;
    }
}
